import revive_payload_client_cgkMYujL5M from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_encoding@0.1.13_es_jku3xkkkqori22codjl52zyhry/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_sb23tC4FML from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_encoding@0.1.13_es_jku3xkkkqori22codjl52zyhry/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_LuehoCzKO4 from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_encoding@0.1.13_es_jku3xkkkqori22codjl52zyhry/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_LGBpgGz9dY from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_encoding@0.1.13_es_jku3xkkkqori22codjl52zyhry/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_TX29XsH4sr from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_encoding@0.1.13_es_jku3xkkkqori22codjl52zyhry/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_GHA6JKhzO4 from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.14.3_typescript@5.4.5_vue@3.4.23_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_6IraTQrm6t from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_encoding@0.1.13_es_jku3xkkkqori22codjl52zyhry/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_jvMJSr5T7M from "/vercel/path0/node_modules/.pnpm/nuxt-gtag@3.0.1_magicast@0.3.4_rollup@4.14.3_webpack-sources@3.2.3/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import motion_swH10RehfY from "/vercel/path0/node_modules/.pnpm/@vueuse+motion@2.1.0_rollup@4.14.3_vue@3.4.23_typescript@5.4.5_/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import chunk_reload_client_yJicyQb4Eg from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.4_encoding@0.1.13_es_jku3xkkkqori22codjl52zyhry/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_VfyLQLDK7q from "/vercel/path0/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.1_rollup@4.14.3_typescript@5.4.5_vue@_xadz2kmg7bhiz5qp7hdkk5icvi/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import vimeo_player_5an1omvbNt from "/vercel/path0/plugins/vimeo-player.js";
import api_client_oAhitvIGap from "/vercel/path0/plugins/api-client.ts";
import sentry_client_shVUlIjFLk from "/vercel/path0/plugins/sentry.client.ts";
import toastify_ScRgZgP9op from "/vercel/path0/plugins/toastify.ts";
import vue_query_wrmMkNpEpe from "/vercel/path0/plugins/vue-query.ts";
export default [
  revive_payload_client_cgkMYujL5M,
  unhead_sb23tC4FML,
  router_LuehoCzKO4,
  payload_client_LGBpgGz9dY,
  check_outdated_build_client_TX29XsH4sr,
  plugin_vue3_GHA6JKhzO4,
  components_plugin_KR1HBZs4kY,
  prefetch_client_6IraTQrm6t,
  plugin_client_jvMJSr5T7M,
  motion_swH10RehfY,
  chunk_reload_client_yJicyQb4Eg,
  plugin_VfyLQLDK7q,
  vimeo_player_5an1omvbNt,
  api_client_oAhitvIGap,
  sentry_client_shVUlIjFLk,
  toastify_ScRgZgP9op,
  vue_query_wrmMkNpEpe
]