<template>
  <ConfigProvider :use-id="useIdFunction">
    <div>
      <NuxtLoadingIndicator color="#16114F" :height="4" />
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
    </div>
    <VueQueryDevtools />
  </ConfigProvider>
</template>

<script setup lang="ts">
import "@stripe/stripe-js";
import { ConfigProvider } from "radix-vue";
import { VueQueryDevtools } from "@tanstack/vue-query-devtools";
import { useQueryClient } from "@tanstack/vue-query";
import { memberQueries } from "./domains/member/queries";
const useIdFunction = () => useId();

const queryClient = useQueryClient();

useHead({
  titleTemplate: (title) =>
    title ? `${title} | Dollar Donation Club` : "Dollar Donation Club",
});

onServerPrefetch(async () => {
  await queryClient.prefetchQuery({
    ...memberQueries.self(),
  });
});
</script>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.2s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
