import { queryOptions } from "@tanstack/vue-query";
import camelize from "camelcase-keys";
import type { Campaign } from "~/lib/campaigns";
import { useAccessToken } from "./composables";

export const memberQueries = {
  all: () => ["members"],
  self: () =>
    queryOptions({
      queryKey: [...memberQueries.all(), "self"],
      queryFn: async () => {
        const token = useAccessToken();

        if (!token.value) {
          return null;
        }

        const api = useApi();
        try {
          const data = await api<SelfResponse>("/v1/members/self", {
            method: "GET",
          });
          return camelize(data, { deep: true });
        } catch {
          return null;
        }
      },
      staleTime: 1000 * 60 * 60,
    }),
  details: () => [...memberQueries.all(), "detail"],
  detail: (memberId: MaybeRef<number>) =>
    queryOptions({
      queryKey: [...memberQueries.details(), memberId],
      queryFn: async () => {
        const api = useApi();
        const data = await api<DetailResponse>(
          `/v1/members/${toValue(memberId)}`,
          {
            method: "GET",
          },
        );

        return camelize(data, { deep: true });
      },
      staleTime: 1000 * 60 * 5,
    }),
  asGuest: (memberId: MaybeRef<number>) =>
    queryOptions({
      queryKey: [...memberQueries.detail(memberId).queryKey, "asGuest"],
      queryFn: async () => {
        const api = useApi();
        const data = await api<DetailResponse>(
          `/v1/members/asGuest/${toValue(memberId)}`,
          {
            method: "GET",
          },
        );

        return camelize(data, { deep: true });
      },
      staleTime: 1000 * 60 * 5,
    }),
  family: (memberId: MaybeRef<number>) =>
    queryOptions({
      queryKey: [...memberQueries.detail(memberId).queryKey, "family"],
      queryFn: async () => {
        const api = useApi();
        const data = await api<FamilyResponse>(
          `/v1/members/${toValue(memberId)}/family`,
          {
            method: "GET",
          },
        );

        return camelize(data, { deep: true });
      },
      staleTime: 1000 * 60 * 10,
    }),
  datastore: (memberId: MaybeRef<number>) =>
    queryOptions({
      queryKey: [...memberQueries.detail(memberId).queryKey, "datastore"],
      queryFn: async () => {
        const api = useApi();
        const data = await api<Record<string, unknown>>(
          `/v1/members/${toValue(memberId)}/datastore`,
          {
            method: "GET",
          },
        );

        return camelize(data, { deep: true });
      },
      staleTime: 1000 * 60 * 10,
    }),
};

type SelfResponse = {
  id: number;
  created_at: string;
  updated_at: string;
  parent_id: number | null;
  status: string;
  type: "member" | "partner";
  email: string;
  points: number;
  referrals: number;
  first_name: string;
  last_name: string;
  street: string | null;
  city: string;
  state: string;
  postal_code: string | null;
  country: string;
  referral_code: string;
  avatar_path: string | null;
  message: string | null;
  permissions: string[];
  deleted_at: string | null;
  leaderboards: {
    [key in "global" | "country" | "state" | "city"]: {
      rank: number;
      id: number;
      first_name: string;
      last_name: string;
      points: number;
      referrals: number;
      created_at: string;
      city: string;
      state: string;
      country: string;
      partner_slug?: string;
      partner_name?: string;
    }[];
  };
  ranks: {
    leaderboard: "member" | "partner";
    city: number;
    state: number;
    country: number;
    global: number;
  };
  level: string;
  avatar_url: string | null;
  partner: {
    id: number;
    created_at: string;
    updated_at: string;
    member_id: number;
    name: string;
    slug: string;
  } | null;
  metrics: { campaign_id: Campaign; points: number; amount: number }[];
};

type DetailResponse = {
  id: number;
  created_at: string;
  updated_at: string;
  parent_id: number | null;
  status: string;
  type: "member" | "partner";
  points: number;
  referrals: number;
  first_name: string;
  last_name: string;
  city: string;
  state: string;
  country: string;
  referral_code: string;
  avatar_path: string | null;
  message: string | null;
  permissions: string[];
  deleted_at: string | null;
  leaderboards: {
    [key in "global" | "country" | "state" | "city"]: {
      rank: number;
      id: number;
      first_name: string;
      last_name: string;
      points: number;
      referrals: number;
      created_at: string;
      city: string;
      state: string;
      country: string;
    }[];
  };
  ranks: {
    leaderboard: "member" | "partner";
    city: number;
    state: number;
    country: number;
    global: number;
  };
  level: string;
  avatar_url: string | null;
  metrics: { campaign_id: Campaign; points: number; amount: number }[];
  partner: {
    id: number;
    created_at: string;
    updated_at: string;
    member_id: number;
    name: string;
    slug: string;
  } | null;
};

type FamilyResponse = {
  id: number;
  points: number;
  referrals: number;
  first_name: string;
  last_name: string;
  avatar_url: string | null;
  metrics: {
    campaign_id: Campaign;
    points: number;
    amount: number;
  }[];
  family: FamilyResponse[];
};
