import { useQuery } from "@tanstack/vue-query";
import { memberQueries } from "./queries";

export const useAccessToken = () =>
  useCookie("access_token", {
    sameSite: "strict",
    secure: import.meta.env.PROD,
    path: "/",
  });

export const useSelf = () => {
  return useQuery(memberQueries.self());
};
